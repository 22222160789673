import React from 'react';

function PrivacyPolicy() {
    return (
        <section className="py-16 px-4 sm:px-6 md:px-8 bg-gray-50">
            <div className="max-w-3xl mx-auto">
                <h1 className="text-4xl font-bold mb-8">Privacy Policy for Zaynard</h1>
                <p className="text-gray-700 mb-4">Last updated: 08/27/2024</p>

                <p className="mb-4">Zaynard L.L.C. ("us", "we", or "our") operates the website <a href="https://www.zaynard.com" className="text-blue-500 underline">https://www.zaynard.com</a> (the "Service"). This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">1. Information Collection and Use</h2>
                <p className="mb-4">We collect several different types of information for various purposes to provide and improve our Service to you.</p>

                <h3 className="text-xl font-semibold mt-6 mb-2">Types of Data Collected:</h3>
                <h4 className="text-lg font-semibold mt-4 mb-2">a) Personal Data</h4>
                <p className="mb-4">While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
                <ul className="list-disc list-inside mb-4">
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Phone number</li>
                    <li>Address, State, Province, ZIP/Postal code, City</li>
                </ul>

                <h4 className="text-lg font-semibold mt-4 mb-2">b) Usage Data</h4>
                <p className="mb-4">We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">2. Use of Data</h2>
                <p className="mb-4">Zaynard L.L.C. uses the collected data for various purposes:</p>
                <ul className="list-disc list-inside mb-4">
                    <li>To provide and maintain our Service</li>
                    <li>To notify you about changes to our Service</li>
                    <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                    <li>To provide customer support</li>
                    <li>To gather analysis or valuable information so that we can improve our Service</li>
                    <li>To monitor the usage of our Service</li>
                    <li>To detect, prevent and address technical issues</li>
                </ul>

                <h2 className="text-2xl font-semibold mt-8 mb-4">3. Data Retention</h2>
                <p className="mb-4">Zaynard L.L.C. will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our legal agreements and policies.</p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">4. Transfer of Data</h2>
                <p className="mb-4">Your information, including Personal Data, may be transferred to and maintained on computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">5. Disclosure of Data</h2>
                <p className="mb-4">Zaynard L.L.C. may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                <ul className="list-disc list-inside mb-4">
                    <li>Comply with a legal obligation</li>
                    <li>Protect and defend the rights or property of Zaynard L.L.C.</li>
                    <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                    <li>Protect the personal safety of users of the Service or the public</li>
                    <li>Protect against legal liability</li>
                </ul>

                <h2 className="text-2xl font-semibold mt-8 mb-4">6. Security of Data</h2>
                <p className="mb-4">The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">7. Your Rights</h2>
                <p className="mb-4">Zaynard L.L.C. aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data. If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">8. Changes to This Privacy Policy</h2>
                <p className="mb-4">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">9. Contact Us</h2>
                <p className="mb-4">If you have any questions about this Privacy Policy, please contact us:</p>
                <ul className="list-disc list-inside mb-4">
                    <li>By email: <a href="mailto:lennie@zaynard.com" className="text-blue-500 underline">lennie@zaynard.com</a></li>
                    <li>By visiting this page on our website: <a href="https://www.zaynard.com" className="text-blue-500 underline">https://www.zaynard.com</a></li>
                </ul>

                <p className="mb-4">Zaynard L.L.C.<br />
                    5534 Saint Joe Road<br />
                    Fort Wayne, IN, 46835<br />
                    United States</p>
            </div>
        </section>
    );
}

export default PrivacyPolicy;
