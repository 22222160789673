import React from 'react';

function Header() {
    return (
        <header className="max-w-6xl mx-auto my-2">
            <h1 className="text-4xl font-bold text-center font-sans">Zaynard</h1>
        </header>
    );
}

export default Header;
