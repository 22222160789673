import React from 'react';

function Hero() {
    return (
        <>
            <section className="pt-20 pb-10 sm:pt-28 sm:pb-14 text-center px-4 sm:px-6 md:px-8">
                <h2 className="text-6xl font-bold mb-4 font-serif">Actuarial Automation</h2>
                <p className="text-xl mb-8 font-sans pt-6">Reduce costs and improve accuracy by automating your processes using Python and AI agents.</p>
                {/*<a href="#pricing-table">*/}
                {/*    <button className="bg-black text-white px-16 py-5 text-xl font-semibold rounded-lg transform transition-all duration-300 hover:scale-105 mt-4" style={{ boxShadow: '0 10px 25px rgba(0, 0, 0, 0.8)' }}>*/}
                {/*        See plans*/}
                {/*    </button>*/}
                {/*</a>*/}

                

                <div className="flex justify-center items-center mt-6 mb-2"> {/* Reduced margin-bottom */}
                    <span className="h-4 w-4 bg-red-500 rounded-full animated-dot"></span>
                    <span className="ml-2 text-gray-700">Available in February 2025</span>
                </div>

                <p className="mt-6 text-gray-500">
                    Please email us: <a href="mailto:lennie@zaynard.com" className="text-black font-semibold underline">lennie@zaynard.com</a>
                </p>
                <p className="mt-4 text-gray-500">
                    Or call us: +1 (574) 265-6594
                </p>
            </section>

            

        </>
    );
}

export default Hero;
