import React from 'react';

function TermsAndConditions() {
    return (
        <section className="py-16 px-4 sm:px-6 md:px-8 bg-gray-50">
            <div className="max-w-3xl mx-auto">
                <h1 className="text-4xl font-bold mb-8">Terms and Conditions for Zaynard</h1>
                <p className="text-gray-700 mb-4">Last updated: 08/29/2024</p>

                <p className="mb-4">
                    Please read these Terms and Conditions carefully before using the <a href="https://www.zaynard.com" className="text-blue-500 underline">https://www.zaynard.com</a> website (the "Service") operated by Zaynard L.L.C. ("us", "we", or "our").
                </p>

                <p className="mb-4">
                    By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, you may not access the Service.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">1. Intellectual Property Rights</h2>
                <p className="mb-4">
                    The content on our website, including text, graphics, logos, and software, is the property of Zaynard L.L.C. and is protected by copyright and other intellectual property laws. You may not use, reproduce, or distribute our content without our express written permission.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">2. Services and Subscriptions</h2>
                <ul className="list-disc list-inside mb-4">
                    <li className="mb-2">Zaynard L.L.C. offers website design and development services under three main plans: Silver, Gold, and Diamond.</li>
                    <li className="mb-2">The Silver plan is a one-time payment option, best suited for one-page websites.</li>
                    <li className="mb-2">The Gold and Diamond plans are monthly subscription services.</li>
                    <li className="mb-2">Subscriptions for Gold and Diamond plans can be paused or cancelled after the first month of use.</li>
                    <li className="mb-2">We do not offer refunds for any payments made, but we will revise the design until you are 100% satisfied with the final result.</li>
                    <li className="mb-2">Cancellation can be initiated by sending an email to us.</li>
                </ul>

                <h2 className="text-2xl font-semibold mt-8 mb-4">3. Third-Party Costs</h2>
                <p className="mb-4">
                    Clients are responsible for paying all server costs and domain name fees. While we can assist with these services, any associated costs will be discussed and agreed upon separately from our main service fees.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">4. Prohibited Activities</h2>
                <p className="mb-4">
                    Users of our service agree not to engage in any illegal activities, violate any applicable laws, or use our service to harm others or damage our reputation.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">5. Management and Oversight</h2>
                <p className="mb-4">
                    We reserve the right to monitor the use of our service and take appropriate action against any violations of these terms.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">6. Service Interruptions</h2>
                <p className="mb-4">
                    We do not guarantee uninterrupted access to our service. We are not liable for any losses or inconveniences caused by service interruptions.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">7. Governing Law</h2>
                <p className="mb-4">
                    These Terms are governed by the laws of Indiana, United States.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">8. Disclaimer</h2>
                <p className="mb-4">
                    Our service is provided "as is" without any warranties. We are not responsible for any errors, inaccuracies, or damages resulting from your use of our service.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">9. Limitation of Liability</h2>
                <p className="mb-4">
                    Zaynard L.L.C. is not liable for any indirect, incidental, or consequential damages resulting from your use of our service.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">10. User Data</h2>
                <p className="mb-4">
                    You are responsible for all data you transmit through our service. We are not liable for any loss or corruption of your data.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">11. Electronic Communications</h2>
                <p className="mb-4">
                    By using our service, you consent to receive electronic communications from us and agree that all agreements and notices can be provided electronically.
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">12. Contact Us</h2>
                <p className="mb-4">If you have any questions about these Terms, please contact us:</p>
                <ul className="list-disc list-inside mb-4">
                    <li>By email: <a href="mailto:lennie@zaynard.com" className="text-blue-500 underline">lennie@zaynard.com</a></li>
                    <li>By visiting this page on our website: <a href="https://www.zaynard.com" className="text-blue-500 underline">https://www.zaynard.com</a></li>
                </ul>

                <p className="mb-4">Zaynard L.L.C.<br />
                    5534 Saint Joe Road<br />
                    Fort Wayne, IN, 46835<br />
                    United States</p>
            </div>
        </section>
    );
}

export default TermsAndConditions;